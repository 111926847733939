import { ChainId, Currency, Token } from '@uniswap/sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import CircleEth from '../../assets/svg/circle_ETH.svg'
// import EthereumLogo from '../../assets/images/ethereum-logo.png'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import { useActiveWeb3React } from '../../hooks'
import { Symbol } from '../../constants'
import tokenLogoUriList from '../../assets/tokenLogoUriList.json'
import unknownUrl from 'assets/svg/circle_unknown.svg'

export const getTokenLogoURL = (address: string) =>
  `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`

export const getAntimatterTokenLogoURL = (address: string) =>
  `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  background-color: ${({ theme }) => theme.white};
`

const AvalancheLogo = 'https://raw.githubusercontent.com/sushiswap/icons/master/token/avax.jpg'
const BinanceCoinLogo = 'https://raw.githubusercontent.com/sushiswap/icons/master/token/bnb.jpg'
// const FantomLogo = 'https://raw.githubusercontent.com/sushiswap/icons/master/token/ftm.jpg'
// const HarmonyLogo = 'https://raw.githubusercontent.com/sushiswap/icons/master/token/one.jpg'
// const HecoLogo = 'https://raw.githubusercontent.com/sushiswap/icons/master/token/heco.jpg'
// const MaticLogo = 'https://raw.githubusercontent.com/sushiswap/icons/master/token/polygon.jpg'
// const MoonbeamLogo = 'https://raw.githubusercontent.com/sushiswap/icons/master/token/eth.jpg'
// const OKExLogo = 'https://raw.githubusercontent.com/sushiswap/icons/master/token/okt.jpg'
// const xDaiLogo =
//   'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/xdai/assets/0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d/logo.png'
// const CeloLogo = 'https://raw.githubusercontent.com/sushiswap/icons/master/token/celo.jpg'
// const PalmLogo = 'https://raw.githubusercontent.com/sushiswap/icons/master/token/palm.jpg'
// const MovrLogo = 'https://raw.githubusercontent.com/sushiswap/icons/master/token/movr.jpg'

const LOGO: { readonly [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: CircleEth,
  [ChainId.ROPSTEN]: CircleEth,
  [ChainId.BSC]: BinanceCoinLogo,
  [ChainId.Avalanche]: AvalancheLogo,
  [ChainId.Arbitrum]: CircleEth
}

// const unknown = 'https://raw.githubusercontent.com/sushiswap/icons/master/token/unknown.png'

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
  currencySymbol
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
  currencySymbol?: string
}) {
  const { chainId } = useActiveWeb3React()
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (!currency && !currencySymbol) {
      return [unknownUrl]
    }
    if (currencySymbol) {
      const uri = (tokenLogoUriList as any)[currencySymbol]
      if (uri) return [uri]
    }

    if (currency?.symbol) {
      const uri = (tokenLogoUriList as any)[currency.symbol]
      if (uri) return [uri]
    }

    // if (currency === ETHER) {
    //   return [LOGO[chainId ?? 1] ?? '']
    // }

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }
      return [getTokenLogoURL(currency.address)]
    }

    return []
  }, [currency, currencySymbol, uriLocations])

  if (
    srcs.length === 0 &&
    (currency?.symbol === Symbol[chainId ?? 1] || currency?.symbol === 'W' + Symbol[chainId ?? 1])
  ) {
    return <StyledEthereumLogo src={LOGO[chainId ?? 1]} size={size} style={style} />
  }

  // if (currency === ETHER || currency?.symbol === 'WETH') {
  //   return <StyledEthereumLogo src={LOGO[chainId ?? 1]} size={size} style={style} />
  // }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
