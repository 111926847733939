import { ChainId } from '@uniswap/sdk'

export const SUPPORTED_NETWORKS: {
  [chainId in ChainId]?: {
    chainId: string
    chainName: string
    nativeCurrency: {
      name: string
      symbol: string
      decimals: number
    }
    rpcUrls: string[]
    blockExplorerUrls: string[]
  }
} = {
  [ChainId.MAINNET]: {
    chainId: '0x1',
    chainName: 'Ethereum',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: ['https://mainnet.infura.io/v3'],
    blockExplorerUrls: ['https://etherscan.com']
  },
  [ChainId.BSC]: {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18
    },
    rpcUrls: ['https://bsc-dataseed.binance.org'],
    blockExplorerUrls: ['https://bscscan.com']
  },
  [ChainId.Avalanche]: {
    chainId: '0xA86A',
    chainName: 'Avalanche',
    nativeCurrency: {
      name: 'Avalanche Token',
      symbol: 'AVAX',
      decimals: 18
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://cchain.explorer.avax.network']
  },
  [ChainId.Arbitrum]: {
    chainId: '0xA4B1',
    chainName: 'Arbitrum',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://mainnet-arb-explorer.netlify.app']
  }
}

export type SUPPORTED_NETWORKS_KEYS = keyof typeof SUPPORTED_NETWORKS

export const CHAIN_ID = Object.keys(SUPPORTED_NETWORKS).reduce((acc, key) => {
  const network = SUPPORTED_NETWORKS[+key as keyof typeof SUPPORTED_NETWORKS]
  if (!network) return acc
  acc[network?.chainName] = +key
  return acc
}, {} as any)

export const CHAIN_ID_LIST = Object.keys(SUPPORTED_NETWORKS).reduce((acc, key) => {
  const network = SUPPORTED_NETWORKS[+key as keyof typeof SUPPORTED_NETWORKS]
  if (!network) return acc
  acc.push(+key)
  return acc
}, [] as number[])
